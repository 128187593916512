import classes from './Weather.module.css'
import Lottie from 'react-lottie-player'
import test from '../lottie/rainlottie.json'
import React, { useEffect, useRef, useState } from "react";
import { motion } from 'framer-motion';

const myWeather = [];

function Content(props){
    

    if (props.i != -1) {
        myWeather[props.i] = props.info;
      }
    return(
        <div className={classes.holder} >
            {myWeather.map((report) => (
        <div key={report && report.sys ? report.sys.id : ""} className={classes.content}>
            <div className={classes.cityDiv} >
                <p className={classes.cityName}>{report.name}</p>
            </div>
            <div className={classes.tempDiv} >
                <p className={classes.temp}>{Math.floor(report && report.main ? report.main.temp : "")}<sup style={{fontSize: '3rem'}}>°f</sup></p>
                <p className={classes.desc} >{report && report.weather[0] ? report.weather[0].description : ""}</p>
            </div>
            <div className={classes.iconDiv} >
            <Lottie
                loop
                animationData={test}
                play
                style={{ width: 150, height: 150 }}
            />
            </div>
            <div className={classes.row2}>
                <div className={classes.feelContainers} >
                    <p className={classes.titles}>Feels Like: {Math.floor(report && report.main ? report.main.feels_like : "")}<sup style={{fontSize: '1rem'}}>°f</sup></p>
                </div>
                <div >
                    <p className={classes.titles}>Min Temp: {Math.floor(report && report.main ? report.main.temp_min : "")}<sup style={{fontSize: '1rem'}}>°f</sup></p>
                </div>
                <div >
                    <p className={classes.titles}>Max Temp: {Math.floor(report && report.main ? report.main.temp_max : "")}<sup style={{fontSize: '1rem'}}>°f</sup></p>
                </div>
                
            </div>
            <div className={classes.day} >
                <p className={classes.dayTitle} >Pressure</p>
                <p className={classes.value} >{Math.floor(report && report.main ? report.main.pressure : "")}</p>

                <p className={classes.dayTitle}>Humidity</p>
                <p className={classes.value}>{Math.floor(report && report.main ? report.main.humidity : "")}%</p>
            </div>
        </div>))}
        </div>
    )
}

export default Content;