import Cards from "./Cards.js";
import Content from "./Content.js";
import classes from "./Weather.module.css";
import Lottie from "lottie-web";
import Axios from "axios";

import search from "../imgs/search.png";
import navIcon from "../imgs/whiteNav.png";
import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";



function Weather() {
  let zip = "";
  var long; //Holds Longitude Value of User
  var lat; //Holds Latitude Value of User
  var api;
  const weatherResults = useRef();
  const [data, setData] = useState("");
  const [name, setName] = useState("");
  var id = "691553af8faa4be6c4b2e4915053da9e"; //My Api ID
  const [index, setIndex] = useState(-1);
  const [isAnimating, setIsAnimating] = useState(false);

  const onKeyDownHandler = e => {
    if (e.keyCode === 'Enter') {
      getZip();
    }
  }

  /*const Body = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    background-image: linear-gradient(to bottom right, #6111a1, #03003c);
    background-size: cover;
    background-repeat: no-repeat;
    width: 100vw;
    height: 100vh;
    opacity: 1;
  `;*/

  const weather = [];
  function getZip() {
    zip = data;
    console.log(zip);
    

    Axios.get(
      `http://api.openweathermap.org/data/2.5/weather?zip=${zip},us&appid=${id}&units=imperial`
    )
      .then((response) => {
        //console.log(response.data.name)
        setIndex(index + 1);
        setName(response.data);
      })
      .catch((err) => {
        console.log("error");
      });
    //console.log(name);
    return <Content info={name}/>;
  }
  function getCurrent() {
    const successCallback = (position) => {
      //Sets Longitude Value
      long = position.coords.longitude;
      //Sats Latitude Value
      lat = position.coords.latitude;
      //FIlls Api link date in
      api = `http://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${long}&appid=${id}&units=imperial`;

      Axios.get(api)
      .then((response) => {
        setIndex(index + 1);
        setName(response.data);
      })
      .catch((err) => {
        console.log("error");
      });
    };
    const errorCallback = (error) => {
      console.log("error");
      console.log(error);
    };
    navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
    console.log(name)
    return <Content info={name} i={index} />
  }

  const weatherLogo = useRef(null);
    useEffect(() => {
      Lottie.loadAnimation({
        container: weatherLogo.current,
        renderer: "canvas",
        loop: true,
        autoplay: true,
        animationData: require("../lottie/weatherlogo.json"),
      });
    }, []);
  const dmToggle = useRef(null);
    useEffect(() => {
      dmToggle.current = Lottie.loadAnimation({
        container: dmToggle.current,
        renderer: "canvas",
        loop: false,
        autoplay: false,
        animationData: require("../lottie/dmtoggle.json"),
      });

      return () => dmToggle.current?.destroy();
    }, []);

  return (
    <div className={classes.weather}>
      <motion.div
        className={classes.body}
        animate={{ zIndex: -1, opacity:1 }}
        initial={{ opacity: 0 }}
      ></motion.div>
      <div
        className={classes.h1}
        as={motion.div}
        animate={{ y: 25, opacity: 1 }}
        initial={{ y: -25, opacity: 0 }}
      >
        <div className={classes.img} ref={weatherLogo} >

        </div>
        <h1>Weather Tracker</h1>
      </div>
      <form className={classes.bar}>
        <input
          type="text"
          placeholder="Enter a Zipcode"
          className={classes.search}
          value={data}
          onChange={(e) => setData(e.target.value)}
          onKeyPress={(e) => { if (e.key === 'Enter'){e.preventDefault(); getZip()};}}
        />
        <button type="button" className={classes.sButton} onClick={getZip} >
          <img className={classes.searchIcon} src={search}  />
        </button>
      </form>
      <div className={classes.cards}>
        {/*<Cards
          info={name}
          i={index}
          key={name.id}
          theme={theme}
          setTheme={setTheme}
        />*/}
      </div>
      <div className={classes.container} >
        <Content info={name} i={index} key={name && name.sys ? name.sys.id : ""}/>
      </div>
      
      <div className={classes.toggleContainer}>
        <motion.div
          ref={dmToggle}
          className={classes.dmtoggle}
          onClick={() => {
            setIsAnimating(!isAnimating);
            dmToggle.current?.setDirection(isAnimating ? -1 : 1); 
            dmToggle.current?.play();
          }}
        >
        </motion.div>
        <motion.div className={classes.nav} whileTap={{ scale: .8}} onClick={() => {
            getCurrent();
            
          }} >
          <img className={classes.navIcon} src={navIcon}></img>
        </motion.div>
      </div>
    </div>
  );
}
export default Weather;
