import classes from "./Cards.module.css";
import { useState, useRef, uesEffect } from "react";
import { motion } from "framer-motion";

//import fewClouds from '../imgs/fewClouds.png'
//import App from '../App.js'
const myWeather = [];

function Cards(props) {
  //console.log(props.info.weather.info)
  var index = props.i;
  const [isCardVisible, hideCard] = useState(false);
  const detailID = useRef(null);

  if (props.i != -1) {
    myWeather[props.i] = props.info;
  }

  return (
    <div>
      {myWeather.map((report) => (
        <motion.div
          className={classes.card}
          animate={{
            opacity: 1,
          }}
          initial={{
            opacity: 0,
          }}
        >
          <div className={classes.col}>
            <p className={classes.city}>{report.name}</p>
            <img
              src={
                "http://openweathermap.org/img/wn/" +
                (report && report.weather[0] ? report.weather[0].icon : "") +
                "@2x.png"
              }
            />
          </div>
          <div className={classes.col}>
            <p className={classes.temp}>
              {Math.floor(report && report.main ? report.main.temp : "")}°f
            </p>
          </div>
          <div className={classes.text} >
            <p className={classes.feels}>
              Feels Like:{" "}
              {Math.floor(report && report.main ? report.main.feels_like : "")}
              °f
            </p>
            <p className={classes.desc}>{report && report.weather[0] ? report.weather[0].description : ""} </p>
          </div>
        </motion.div>
      ))}
    </div>
  );
}

export default Cards;
