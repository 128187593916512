import { motion } from "framer-motion";
import { openContext } from "./Home";
import { useContext } from "react";
import { AnimatePresence } from "framer-motion";
import "./ProjectMenu.css";

function ProjectMenu3(props) {
  const { value, value2, value5 } = useContext(openContext);
  const [isOpen, setOpen] = value5;
  return (
    <AnimatePresence>
      {isOpen &&( <motion.div animate={{opacity: 1}} initial={{opacity: 0}} exit={{opacity: 0}} className="menuContainer">
        <p className="desc">Website redesign based on a local trucking company that deals with B2B services.</p>
        <div>
          <motion.button
            whileTap={{ scale: .75 }}
            initial={{ scale: 1 }}
            className="btn"
            onClick={event => {
              event.stopPropagation();
              console.log("Live View");
              window.location.href="http://drfreight.jamargolden.com";
            }}
          >
            Live View
          </motion.button>
          <motion.button
            whileTap={{ scale: .75 }}
            initial={{ scale: 1 }}
            className="btn"
            onClick={event => {
              event.stopPropagation();
              console.log("Read Me");
              window.location.href="https://github.com/jamargolden/SiteTest/blob/main/README.md";
            }}
          >
            .ReadMe
          </motion.button>
        </div>
      </motion.div>)}
    </AnimatePresence>
  );
}
export default ProjectMenu3;
